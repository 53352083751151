import { DeepNullableObj, LegalDocuments } from "core/types";
import { defaultsDeep } from "lodash";
import { TypedMockFn, createMock } from "./index";

const mockLegalDocuments: TypedMockFn<LegalDocuments> = (
  args,
): LegalDocuments =>
  defaultsDeep(
    args,
    createMock(
      "LegalDocuments",
      args,
      (): DeepNullableObj<LegalDocuments> => ({
        dpa: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        privacy_policy: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        terms_and_conditions: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        av_contract: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        addendum: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        disability_statement: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        imprint: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        sign_language: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        easy_language: {
          needs_to_sign: null,
          signed_at: null,
          url: "www.testurl.de",
          version: null,
        },
        __typename: "LegalDocuments",
      }),
    ),
  );

export default mockLegalDocuments;
